// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
import axios from "axios";

axios.defaults.headers["Content-Type"] =  "application/json";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_APP_API;

const state = {
  user_personal_info: {
    photo: "media/klik/logo-min.png",
    name: "",
    surname: "",
    company_name: "",
    job: "",
    email: "",
    phone: "",
    company_site: ""
  },
  user_account_info: {
    username: "",
    email: "",
    language: "",
    time_zone: "",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  },
  session: {
    username: "",
    email: "",
    language: "",
    time_zone: "",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  }
};

const getters = {
	currentUserPersonalInfo(state) {
		var t = JSON.parse(localStorage.getItem('CustomerData'));
		state.user_personal_info.id_customer = t.id_customer;//id_customer
		state.user_personal_info.name = t.shop_name;//nom boutique
		state.user_personal_info.company_name = t.name;//customer name
		state.user_personal_info.email_customer = t.email;
		state.user_personal_info.phone_shop = t.phone_namber;
		state.user_personal_info.company_site = t.url_site;
		state.user_personal_info.mf_customer = t.mf;
		state.user_personal_info.adresse_customer = t.adresse;
		state.user_personal_info.category = t.id_category_customer;
		state.user_personal_info.shop_description = t.description;
		state.user_personal_info.shop_url = t.short_url;
		state.user_personal_info.country = t.id_country;
		

		state.user_personal_info.firstname_contact_customer = t.firstname;
		state.user_personal_info.lastname_contact_customer = t.lastname;
		state.user_personal_info.phone_contact_customer = t.contact_phone_namber;
		
		state.user_personal_info.email_contact_customer = t.email_contact;
		state.user_personal_info.currency = "";
		
		state.user_personal_info.open = t.open;
		state.user_personal_info.whatsapp = t.whatsapp;
		state.user_personal_info.messenger = t.messenger;
		state.user_personal_info.unique_identity = t.unique_identity;
		state.user_personal_info.qr_code = process.env.VUE_APP_APP_API+"/klik/product/img/"+t.qr_code;
		state.user_personal_info.photo = (t.logo != "" && t.logo != null) ? process.env.VUE_APP_APP_API+"/klik/product/img/"+t.logo : "media/klik/logo-min.png"; 
		
		state.user_personal_info.adresse_lat = (t.adresse_lat != "" && t.adresse_lat != null) ? t.adresse_lat : 36.8003165; 
		state.user_personal_info.adresse_lng = (t.adresse_lng != "" && t.adresse_lng != null) ? t.adresse_lng : 10.1857509; 
		
		state.user_personal_info.id_customer_bot_page = t.id_customer_bot_page; 
		
		
		state.user_personal_info.customer_shop_url = t.customer_shop_url; 
		
		var d = JSON.parse(localStorage.getItem('CustomerSettings'));
		state.user_personal_info.settings = {};
		state.user_personal_info.settings.dial = d.dial;
		state.user_personal_info.settings.currency_code = d.currency_code;
		state.user_personal_info.settings.id_customer_settings = d.id_customer_settings;
		state.user_personal_info.settings.minimum_order_for_deliver = d.minimum_order_for_deliver== null ? 0 : d.minimum_order_for_deliver;
		state.user_personal_info.settings.minimum_order_for_free_delivery = d.minimum_order_for_free_delivery== null ? 1000 : d.minimum_order_for_free_delivery;
		state.user_personal_info.settings.delivery_fees = d.delivery_fees == null ? 0 : d.delivery_fees;
		state.user_personal_info.settings.tax = d.tax == null ? 0 : d.tax;
		state.user_personal_info.settings.id_currency = d.id_currency;
		state.user_personal_info.settings.rip = d.rip;
		state.user_personal_info.settings.iban = d.iban;
		state.user_personal_info.settings.code_swift_bic = d.code_swift_bic;
		state.user_personal_info.settings.qrcode_d17 = d.qrcode_d17 == "" ? "media/klik/qr-code.png" : process.env.VUE_APP_APP_API+"/klik/product/img/"+d.qrcode_d17;
		
		
		state.user_personal_info.settings.currency_format = d.currency_format;
		state.user_personal_info.settings.currency_right = d.currency_right;
		
		
		
		state.user_personal_info.settings.bank_transfer = d.bank_transfer == "" ? false : d.bank_transfer;
		state.user_personal_info.settings.postal_order = d.postal_order == "" ? false : d.postal_order;
		state.user_personal_info.settings.cash = d.cash == "" ? false : d.cash;
		state.user_personal_info.settings.digipostbank = d.digipostbank == null ? false : d.digipostban;
		state.user_personal_info.settings.ticket_restaurant = d.ticket_restaurant == null ? false : d.ticket_restauran;
		state.user_personal_info.settings.clictopay = d.clictopay == null ? false : d.clictopay;
		state.user_personal_info.settings.clictopay_login = d.clictopay_login;
		state.user_personal_info.settings.clictopay_password = d.clictopay_password;
		
		state.user_personal_info.settings.delivery = d.delivery;
		state.user_personal_info.settings.self_pickups = d.self_pickups;
		
		var categoryFilter = [4,5,7,8,27];
		
		state.user_personal_info.settings.in_resto_show = (categoryFilter.indexOf(parseInt(t.id_category_customer)) != -1) ? true : false;
		state.user_personal_info.settings.in_resto = d.in_resto;
		state.user_personal_info.settings.delivering_to = d.delivering_to;
		state.user_personal_info.settings.notif_whatsapp = d.notif_whatsapp == "" ? false : d.notif_whatsapp;
		state.user_personal_info.settings.notif_sms = d.notif_sms == "" ? false : d.notif_sms;
		state.user_personal_info.settings.notif_email = d.notif_email == "" ? false : d.notif_email;
		state.user_personal_info.settings.notif_messenger = d.notif_messenger == "" ? false : d.notif_messenger;
		state.user_personal_info.settings.whatsapp = d.whatsapp;
		state.user_personal_info.settings.messenger = d.messenger;
		
		state.user_personal_info.settings.bot = d.bot;
		
		
		//test account active
		state.user_personal_info.settings.hasActive = true;
		state.user_personal_info.settings.hasPay = t.payment;
		
		state.user_personal_info.date_create = parseFloat(t.date_create);
		state.user_personal_info.date_expired = parseFloat(t.date_expired);
		
		var DateNow = new Date().getTime();
		
		if(state.user_personal_info.date_expired < DateNow){
			state.user_personal_info.settings.hasActive = false;
			//state.user_personal_info.settings.hasPay = false;
		}else{//periode essai
			state.user_personal_info.settings.hasActive = state.user_personal_info.date_expired - DateNow;
			//state.user_personal_info.settings.hasPay = false;
		}
		
		
		
		return state.user_personal_info;
	},
	numberFormat:(state)=> (number) =>{
		//number =  number.replace(",", ".");
		number = parseFloat(number);
		var decimals = state.user_personal_info.settings.currency_format.decimals;
		var dec_point = state.user_personal_info.settings.currency_format.dec_point;
		var thousands_sep = state.user_personal_info.settings.currency_format.thousands_sep;
		var currency_code = state.user_personal_info.settings.currency_code;
		var currency_right = state.user_personal_info.settings.currency_right;
		
		var n = !isFinite(+number) ? 0 : +number, 
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			
			toFixedFix = function (n, prec) {
				// Fix for IE parseFloat(0.55).toFixed(0) = 0;
				var k = Math.pow(10, prec);
				return Math.round(n * k) / k;
			},
			s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
			sep = (sep == null || sep == 'null' ) ? ' ' : sep;
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		var cu = s.join(dec)
		if(currency_right){
			return cu+" "+currency_code;
		}
		return currency_code+" "+cu;
	},
	numberFormatShow:(state)=> (number,decimals,dec_point,thousands_sep,currency_code,currency_right) =>{
		//number =  number.replace(",", ".");
		number = parseFloat(number);
		console.log(state);
		/*var decimals = state.user_personal_info.settings.currency_format.decimals;
		var dec_point = state.user_personal_info.settings.currency_format.dec_point;
		var thousands_sep = state.user_personal_info.settings.currency_format.thousands_sep;
		var currency_code = state.user_personal_info.settings.currency_code;
		var currency_right = state.user_personal_info.settings.currency_right;*/
		
		var n = !isFinite(+number) ? 0 : +number, 
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			
			toFixedFix = function (n, prec) {
				// Fix for IE parseFloat(0.55).toFixed(0) = 0;
				var k = Math.pow(10, prec);
				return Math.round(n * k) / k;
			},
			s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
			sep = (sep == null || sep == 'null' ) ? ' ' : sep;
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		var cu = s.join(dec)
		if(currency_right){
			return cu+" "+currency_code;
		}
		return currency_code+" "+cu;
	},
	UpdateSession() {
		//console.log("UpdateSession Profile.mo"+state);
		if(localStorage.getItem('isAuthenticated') != "true"){
			return;
		}
		axios.post("/session").then((response) => {
			if(response.data.login == "error"){
				//location.reload();
			}else if(response.data.login == false){
				//location.reload();
			}else{
				localStorage.setItem('CustomerData', JSON.stringify(response.data.login));
				localStorage.setItem('CustomerSettings', JSON.stringify(response.data.settings));
				localStorage.setItem('TemplateSettings', JSON.stringify(response.data.template));
				localStorage.setItem('isAuthenticated', true);
				return true
			}
			//state.session = response.data.login;
			//context.commit(UPDATE_SESSION, payload);
		}).catch((errors) => {
			console.log(errors);
			localStorage.setItem('isAuthenticated',false);
			//state.session = errors;
			location.reload();
			//context.commit(UPDATE_SESSION, payload);
		});
		return state.session;
		
	},
	DateDifference(state) {
		var daysDifference = Math.floor(state.user_personal_info.settings.hasActive/1000/60/60/24);
		//var difference = daysDifference*1000*60*60*24

		/*var hoursDifference = Math.floor(difference/1000/60/60);
		difference -= hoursDifference*1000*60*60

		var minutesDifference = Math.floor(difference/1000/60);
		difference -= minutesDifference*1000*60

		var secondsDifference = Math.floor(difference/1000);*/
		return daysDifference + ' Jours'	
	},
	StatusPayActiveShop(state) {
		var active = "";//
		//state.user_personal_info.settings.hasActive = false;
		if(state.user_personal_info.settings.hasPay == false){
			//active = "N";
			if(state.user_personal_info.settings.hasActive == false){
				active = "F";//disable
			}else{
				active = "E";//Essai
			}
		}else{
			if(state.user_personal_info.settings.hasActive > 0){
				active = "A";//active
			}else{
				active = "F";//end offre
			}
		}
		console.log("--------------------------------------");
		console.log(active);
		return active;
		
	},
	VerifPublicShop(state) {
		var Msg = [];//
		console.log(state);
		return Msg;
		/*if(state.user_personal_info.settings.notif_whatsapp == false && state.user_personal_info.settings.notif_sms == false && state.user_personal_info.settings.notif_email == false && state.user_personal_info.settings.notif_messenger == false ){
			Msg.push("Créer une nouvelle méthode pour recevoir les commandes (WhatsApp,Messenger,...)");
		}else{
			if(state.user_personal_info.settings.notif_whatsapp == true){
				if(state.user_personal_info.settings.whatsapp == null || state.user_personal_info.settings.whatsapp == ""){
					Msg.push("Saisie un numéro whatsapp pour recevoir les commandes par WhatsApp");
				}
			}
			if(state.user_personal_info.settings.notif_sms == true){
				if(state.user_personal_info.phone_shop == null || state.user_personal_info.phone_shop == ""){
					Msg.push("Saisie un numéro de téléphone pour recevoir les commandes par SMS");
				}
			}
			if(state.user_personal_info.settings.notif_email == true){
				if(state.user_personal_info.settings.email_customer == null || state.user_personal_info.settings.email_customer == ""){
					Msg.push("Saisie un E-mail pour recevoir les commandes par e-mail");
				}
			}
			if(state.user_personal_info.settings.notif_messenger == true){
				if(state.user_personal_info.messenger == null || state.user_personal_info.messenger == ""){
					Msg.push("Sivier le lien pour activer Messenger");
				}
			}
			
		}
		
		if(state.user_personal_info.settings.self_pickups == false && state.user_personal_info.settings.in_resto == false && state.user_personal_info.settings.delivery == false){
			Msg.push("Créer une nouvelle méthode pour recevoir les commandes (Livraison à domicile,Collecte,...)");
		}else{
			if(state.user_personal_info.settings.self_pickups == true){
				if(state.user_personal_info.adresse_customer == null || state.user_personal_info.adresse_customer == ""){
					Msg.push("Saisie un adresse de la boutique");
				}
			}
			if(state.user_personal_info.settings.delivery == true){
				if(state.user_personal_info.settings.email_customer == null || state.user_personal_info.settings.email_customer == ""){
					Msg.push("Saisie un E-mail pour recevoir les commandes");
				}
			}
			
		}
		
		if(state.user_personal_info.settings.bank_transfer == false && state.user_personal_info.settings.postal_order == false && state.user_personal_info.settings.cash == false && state.user_personal_info.settings.digipostbank == false && state.user_personal_info.settings.ticket_restaurant == false && state.user_personal_info.settings.clictopay == false){
			Msg.push("Choisissez les modes de paiement disponibles");
		}else{
			if(state.user_personal_info.settings.bank_transfer == true){
				if(state.user_personal_info.settings.rip == "" || state.user_personal_info.settings.iban == "" || state.user_personal_info.settings.code_swift_bic == ""){
					Msg.push("Vous pouvez recevoir un virement vers votre compte situé dans n’importe quelle banque. Il suffit pour cela que le bénéficiaire vous communique (RIP, IBAN, SWIFT/BIC)");
				}
			}
			if(state.user_personal_info.settings.clictopay == true){
				if(state.user_personal_info.settings.clictopay_login == null || state.user_personal_info.settings.clictopay_password == ""){
					Msg.push("Saisie login mot de passe Clictopay");
				}
			}
			
		}
		
		if(state.user_personal_info.photo == ""){
			Msg.push("Insérer un photo pour votre boutique");
		}
		return Msg;*/
		
	},

  currentUserAccountInfo(state) {
    return state.user_account_info;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
