// USA
export const locale = {
	APP_NAME : "Doken",
	APP_URL : "https://try.doken.co",
	MSG_LOGOUT : "Se déconnecter",
	MSG_UNIQUE_IDENTITY : "Unique Identity(Identité unique)",
	MSG_BANK_TRANSFER : "Virement bancaire",
	MSG_CASH : "Paiement à la livraison",
	MSG_DIGIPOSTBANK : "D17",
	MSG_POSTAL_ORDER : "Mandat postal",
	MSG_TICKET_RESTAURANT : "Ticket Restaurant",
	MSG_CLICTOPAY : "Cartes bancaires",
	MSG_DELIVERY : "Livraison",
	MSG_SELF_PICKUPS : "Collecte",
	MSG_IN_RESTO : "Au restaurant",
	MENU: {
		MSG_ORDER : "Commande",
		MSG_CATEGORY : "Catégorie",
		MSG_PRODUCT : "Produit",
		MSG_SETTINGS : "Configuration",
		MSG_DESIGN : "Design & création",
	},
	AUTH: {
		MSG_EMAIL_ADDRESS_PHONE_NUMBER : "Email address or Phone number",
		MSG_PASSWORD : "Mot de passe",
		MSG_FORGET_PASSWORD : "Mot de passe oublié ?",
		MSG_LOGIN : "S’identifier",
		MSG_NO_ACCOUNT : "Pas de compte ?",
		MSG_CREATE_ACCOUNT : "Créer un compte",
		MSG_ENTER_EMAIL_PHONE_RESET_PASSWORD : "Entrez votre e-mail ou Numéro de téléphone pour réinitialiser votre mot de passe",
		MSG_SEND : "Envoyer",
		MSG_CANCEL : "Annuler",
		MSG_CONTACT : "Contact",
		MSG_IN_REGARDS_TO : "Contact",
		MSG_SUPPORT : "Support",
		MSG_ERROR_LOGIN_PASSWORD : "Adresse e-mail ou mot de passe incorrect ",
		MSG_ERROR_HAS_OCCURRED : "Une erreur s’est produite. Nous nous efforçons de la corriger dans les plus brefs délais.",
	},
	SIGNUP: {
		MSG_SHOP_NAME : "Nom de la boutique en ligne",
		MSG_REGISTER : "S’inscrire",
		MSG_CREATE_STORE_NOW : "Créez votre boutique maintenant",
		MSG_CATEGORY : "Catégorie",
		MSG_COUNTRY : "Pays",
		MSG_PHONE_NUMBER : "Numéro de téléphone",
		MSG_PASSWORD : "Mot de passe",
		MSG_CONFIRM_PASSWORD : "Confirmez le mot de passe",
		MSG_EMAIL : "Adresse e-mail",
		MSG_TERMS_OF_USE : 'En cliquant sur <b>Accepter et s’inscrire</b>, vous acceptez les <a href="https://try.doken.co" class="ml-2"><b>Conditions d’utilisation</b></a> et la <b>Politique relative aux cookies</b>',
		MSG_ACCEPT_AND_REGISTER : "Accepter et s’inscrire",
		MSG_LOGIN : "S’identifier",
		MSG_ERROR_SHOP_NAME_EXISTS : "Nom de boutique existe deja",
		MSG_ERROR_MAIL_NAME_EXISTS : "l'adresse mail existe deja",
		MSG_ERROR_PHONE_NAME_EXISTS : "Numéro de téléphone existe deja",
		MSG_ERROR_MAIL_FORMAT : "S'il vous plaît, mettez une adresse email valide",
		MSG_ERROR_ALL_FIELDS : "Tous les champs sont obligatoires",
		MSG_ERROR_CATEGORY_REQUIRED : "Catégorie obligatoire",
		MSG_ERROR_COUNTRY_REQUIRED : "Pays obligatoire",
		MSG_ERROR_PHONE_REQUIRED : "Numéro de téléphone obligatoire",
		MSG_ERROR_PASSWORD_REQUIRED : "Numéro de téléphone obligatoire",
		MSG_ERROR_PASSWORD_REPASSWORD : "Votre mot de passe et le mot de passe de confirmation ne correspondent pas.",
		MSG_SEND_SMS_TEXT_TITLE : "Vous allez recevoir un SMS ou e-mail de confirmation , cliquez sur le lien pour activer votre compte.",
		MSG_SEND_SMS_TEXT_DESC : "Un message avec un code de vérification à 6 chiffres vient d'être envoyé.",
		MSG_ERROR_PRODUCT : "Une erreur s’est produite. Nous nous efforçons de la corriger dans les plus brefs délais.",
	},
	VERIFSMS: {
		MSG_TITLE_COMPTE_OK : "Votre compte a été créé avec succès",
		MSG_DESC_COMPTE_OK : "Vous devez maintenant utiliser votre compte",
		MSG_TITLE_COMPTE_NOT_OK : "Valider votre compte",
		MSG_DESC_COMPTE_NOT_OK : "Impossible d'utiliser ce code pour la validation",
		MSG_VALIDE : "Valider votre compte",
		MSG_ENTER_CODE : "Saisissez le code",
	},
	ORDER: {
		MSG_DATE_START : "Date début",
		MSG_DATE_END : "Date fin",
		MSG_UPDATE : "Mettre à jour",
		MSG_CANCEL : "Annuler",
		MSG_OK : "Ok",
		MSG_DELETE : "Effacer",
		MSG_EMPTY_DATA : "Il n'y a pas des commandes à afficher",
		MSG_FIND_DATA : "Tapez pour rechercher",
		MSG_MEANS_PAYMENT : "Moyen de paiement :",
		MSG_ORDER : "Livraison :",
		MSG_TABLE : "Table :",
		MSG_USER : "Client :",
		MSG_SHIPPING_COST : "Frais de livraison :",
		MSG_Tax : "Tax :",
		MSG_TOTAL : "Total à payer :",
		MSG_STATUS : "Status : ",
		MSG_PAYMENT : "Paymment : ",
		MSG_NOTE : "Note : ",
		MSG_SEND_NOTIF_TO_USER : "Envoyer une notification au client",
		MSG_SAVE : "Enregistrer les modifications",
		MSG_ORDER_CURRENT_ORDERS : "Commandes en cours",
		MSG_ORDER_WAITING : "En attente",
		MSG_ORDER_CONFIRMED : "Confirmée",
		MSG_ORDER_BEING_PROCESSED : "En cours de traitement",
		MSG_ORDER_SHIPPED : "Expédiée",
		MSG_ORDER_FINALIZED : "Finalisée",
		MSG_ORDER_RECEIVED : "Réceptionnée",
		MSG_ORDER_CANCELED : "Annulée",
		MSG_ORDER_CURRENT_ORDERS_DESCRIPTION : "",
		MSG_ORDER_WAITING_DESCRIPTION : "%shop% vous remercie pour votre confiance ! La commande %num% est En attente de validation par nos équipes. \n %Note%",
		MSG_ORDER_CONFIRMED_DESCRIPTION : "La commande %num% est Confirmée. \n %Note%",
		MSG_ORDER_BEING_PROCESSED_DESCRIPTION : "La commande %num% est En cours de traitement. \n %Note%",
		MSG_ORDER_SHIPPED_DESCRIPTION : "La commande %num% est En cours de livraison. \n %Note%",
		MSG_ORDER_FINALIZED_DESCRIPTION : "La commande %num% est Finalisée. \n %Note%",
		MSG_ORDER_RECEIVED_DESCRIPTION : "La commande %num% est Livrée.  \n %Note%",
		MSG_ORDER_CANCELED_DESCRIPTION : "La commande %num% est Annulée.  \n %Note%",
		MSG_ORDER_PAYMENT_PENDING : "Paiement En attente",
		MSG_ORDER_PAID : "Payé",
		MSG_ORDER_FAILED_PAYMENT : "Paiement Echoué",
		MSG_ORDER_NUMBER : "Numéro de commande : ",
	},
	PRODUCT:{
		MSG_ADD : "Ajouter",
		MSG_REORGANIZE : "Réorganiser",
		MSG_SAVE : "Enregistrer",
		MSG_MASK : "Masque",
		MSG_SHOW : "Afficher",
		MSG_UPDATE : "Mettre à jour",
		MSG_EMPTY_CATEGORY : "Il n'y a aucun article dans cette catégorie",
		MSG_FIRST_PRODUCT : "Créer le 1er produit",
		MSG_FIRST_PRODUCT_URL : "https://www.youtube.com/embed/yO7kQQ3YWzY",
		MSG_FIRST_PRODUCT_ADD : "Ajouter 1er produit",
		MSG_PRODUCT : "Produit",
		MSG_PRODUCT_NAME : "Nom:",
		MSG_PRODUCT_DESC : "Description:",
		MSG_PRODUCT_PRICE : "Prix:",
		MSG_PRODUCT_PIC : "l'image de produit",
		MSG_PRODUCT_FILE_TYPE : "Types de fichiers autorisés: png, jpg, jpeg",
		MSG_CATEGORY : "Catégorie",
		MSG_ERROR_READ_FILE : "Impossible de lire le fichier. Le format n’est pas pris en charge",
		MSG_ERROR_EMPTY_NAME : "Nom est obligatoire",
		MSG_ERROR_EMPTY_DESC : "Description est obligatoire",
		MSG_ERROR_EMPTY_PRICE : "Prix est obligatoire",
		
		MSG_ALERT_STATUS_TITLE : "Êtes-vous sûr?",
		MSG_ALERT_STATUS_TEXT : "Voulez-vous changer le statut de cette article!",
		MSG_ALERT_REMOUVE_OPERA : "Opération annulée",
		
		MSG_SEND : "Valider",
	},
	CATEGORY:{
		MSG_ADD : "Ajouter",
		MSG_REORGANIZE : "Réorganiser",
		MSG_SAVE : "Enregistrer",
		MSG_EMPTY_CATEGORY : "Il n'y a aucun article dans cette catégorie",
		MSG_FIRST_CATEGORY : "Créer la 1ère catégorie",
		MSG_FIRST_CATEGORY_URL : "https://www.youtube.com/embed/yO7kQQ3YWzY",
		MSG_FIRST_CATEGORY_ADD : "Ajouter 1er catégorie",
		MSG_CATEGORY : "Catégorie",
		MSG_SEND : "Envoyer",
		MSG_NAME : "Nom :",
		MSG_ALERT_STATUS_TITLE : "Êtes-vous sûr?",
		MSG_ALERT_STATUS_TEXT : "Voulez-vous changer le statut de cette catégorie!",
		MSG_ERROR_EMPTY_NAME : "Nom de Catégorie est obligatoire",
		MSG_ALERT_REMOUVE_OPERA : "Opération annulée",
	},
	SETTINGS: {
		MSG_GENERAL_INFORMATIONS : "Informations générales",
		MSG_COMMAND_PARAMETERS : "Paramètres de commande",
		MSG_SALES_CHANNEL : "Canal de vente",
		MSG_PAYMENT : "Paiement",
		MSG_SECURITY_CONNECTION : "Sécurité et connexion",
		MSG_CUSTOM_DOMAIN : "Domaine personnalisé",
		MSG_EMAIL : "Adresse e-mail : ",
		MSG_CUSTOMER_NAME : "Nom de la compagnie :",
		MSG_PHONE_NUMBER : "N° de téléphone:",
		GENERAL_INFORMATIONS : {
			MSG_TITLE_GENERAL_INFORMATIONS : "Informations générales",
			MSG_TO_VALIDATE : "Valider",
			MSG_STORE_DETAILS : "Détails de la boutique",
			MSG_LOGO : "Logo",
			MSG_FILE_TYPE : "Types de fichiers autorisés: png, jpg, jpeg.",
			MSG_CATEGORY_BUSINESS : "Catégorie Business",
			MSG_SHOP_NAME : "Nom de boutique",
			MSG_DESCRIPTION : "Description",
			MSG_COMPANY_NAME : "Nom de l'entreprise",
			MSG_TAX_IDENTIFICATION : "Matricule fiscal",
			MSG_COUNTRY : "Pays",
			MSG_ADRESSE : "Adresse",
			MSG_SHOP_NUMBER : "Numéro de boutique",
			MSG_STORE_EMAIL_ADDRESS : "Adresse e-mail de boutique",
			MSG_CONTACT_INFO: "Contact Info",
			MSG_CONTACT_FIRSTNAME: "Prénom",
			MSG_CONTACT_LASTNAME: "Nom",
			MSG_CONTACT_NUMBER: "Numéro du contact",
			MSG_EMAIL : "Adresse e-mail",
			MSG_COMPANY_WEBSITE : "Site de l'entreprise",
			MSG_READ_FILE : "Impossible de lire le fichier. Le format n’est pas pris en charge",
			MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
		},
		DOMAIN : {
			MSG_TITLE_DOMAIN : "Utilisez votre propre domaine",
			MSG_DESC_DOMAIN : "Vous pouvez bénéficier de tous les avantages même si vous possédez déjà un nom de domaine venant d'un autre fournisseur.",
			MSG_TO_VALIDATE : "Valider",
			MSG_SHOP_ADDRESS_LOCAL : "Boutique adresse local",
			MSG_SHOP_TOOLTIP_1 : "Vous ne pouvez avoir qu’un seul nom de sous domaine pour votre boutique, et il est impossible de choisir un nom de  sous domaine déjà utilisé par quelqu’un d’autre.",
			MSG_SHOP_TOOLTIP_2 : "Les noms ne peuvent contenir que des caractères alphanumériques (A à Z, 0 à 9) et des tiré  (-). Ils ne doivent pas comporter de termes génériques ni d’extensions (.com ou .net).",
			MSG_SHOP_TOOLTIP_3 : "Les noms d’utilisateur doivent comprendre au moins 5 caractères.",
			MSG_SHOP_LINKED_STORE : "Domaine à lier à votre boutique",
			MSG_SHOP_CREATE_A_RECORD : "Créez un enregistrement A sur votre registraire de domaine et pointez-le vers",
			MSG_SHOP_TOOLTIP_4 : "Vous ne pouvez avoir qu’un seul nom de sous domaine pour votre boutique, et il est impossible de choisir un nom de  sous domaine déjà utilisé par quelqu’un d’autre.",
			MSG_SHOP_TOOLTIP_5 : "Les noms ne peuvent contenir que des caractères alphanumériques (A à Z, 0 à 9) et des tiré  (-). Ils ne doivent pas comporter de termes génériques ni d’extensions (.com ou .net).",
		    MSG_SHOP_TOOLTIP_6 : "Les noms d’utilisateur doivent comprendre au moins 5 caractères.",
		    MSG_INVALID_DOMAIN_FORMAT : "Format de domaine invalide",
			MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
			MSG_STORE_NAME_ALREADY_USE : "Le nom de boutique déjà utilisé",
			MSG_STORE_NAME_LONG : "Le nom de boutique adresse doivent comprendre au moins 5 caractères.",
			MSG_STORE_URL_PUBLIC : "Adresse publique",
			MSG_STORE_URL_PRIVET : "Votre nom de domaine (boutique.com OU shop.boutique.com)",
		},
		ORDER : {
			MSG_TITLE_ORDER : "Paramètres de commande",
			MSG_TO_VALIDATE : "Valider",
			MSG_CHOOSE_CURRENCY : "Choisissez une devise",
			MSG_CHOOSE_FORM_CURRENCY : "Choisissez une format devise",
			MSG_CHOOSE_RIGHT_LEFT : "Gauche/Droite",
			MSG_ACCEPT_ORDERS_FOR : "Accepter les commandes pour",
			MSG_HOME_DELIVERY : "Livraison à domicile",
			MSG_SELF : "Collecte",
			MSG_AT_TABLE : "À table ",
			MSG_DELIVERING_TO : "Livrant à:",
			MSG_DELIVERING_TO_PLACEHOLDER : "Zone de livraison : Grand Tunis, .....",
			MSG_COMMAND_SETTINGS : "Paramètres de commande:",
			MSG_MINIMUM_ORDER_DELIVERY : "Commande minimum pour la livraison",
			MSG_MINIMUM_ORDER_FREE_DELIVERY : "Commande minimum pour une livraison gratuite",
			MSG_SHIPPING_COST : "Frais de livraison",
			MSG_SHIPPING_TAX : "Tax",
			MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
		},
		SALES_CHANNEL : {
			MSG_TITLE_SALES_CHANNEL : "Canal de vente",
			MSG_DES_WHATSAPP : "Recevez des commandes sur votre numéro WhatsApp",
			MSG_START : "Démarrer ",
			MSG_DES_FACEBOOK : "Connectez votre boutique à votre page Facebook<br>(autorisations d'administrateur requises)",
			MSG_DES_SMS : "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
			MSG_DES_SMTP : "Envoyez des e-mails directement depuis votre chatbot via votre propre serveur SMTP",
			MSG_TITLE_POPUP_FB : "Paramètres Messenger",
			MSG_REFRESH : "Rafraîchir",
			MSG_CREATE_PAGE : "Créer une page",
			MSG_TITLE_POPUP_WA : "Paramètres WhatsApp",
			MSG_TITLE_NUMBER_WA : "N° WhatsApp",
			MSG_TITLE_POPUP_SMS : "Le service SMS est un service avec facturer des frais supplémentaires",
			MSG_FEATURE_UNDER_DEVELOPMENT : "Fonctionnalité en cours de développement",
			MSG_ERROR_CNX_FB : "Une erreur s'est produite lors de la connexion à Facebook!<br> Veuillez réessayer.",
			MSG_ERROR_WA : "Un numéro WhatsApp est obligatoire pour utiliser Notification sur WhatsApp",
			MSG_ERROR_SMS : "Un numéro de téléphone est obligatoire",
			MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
			MSG_TO_VALIDATE : "Valider",
		},
		PAYMENT : {
			MSG_TITLE_PAYMENT : "Paramètres de Paiement",
			MSG_DESC_PAYMENT : "Ajouter un moyen de paiement",
			MSG_DESC_BANK_TRANSFER : "Vous pouvez recevoir un virement  vers votre compte situé dans n’importe quelle banque. Il suffit pour cela que le bénéficiaire vous communique :",
			MSG_RIP : "RIP",
			MSG_RIP_DESC : "Le RIB (Relevé d'Identité Bancaire) si son compte se trouve dans une banque locale.",
			MSG_IBAN : "IBAN",
			MSG_IBAN_DESC : "L’IBAN est le format international de votre numéro de compte courant. Le format IBAN permet d’automatiser les paiements et transferts sur le plan international et d’en réduire les coûts.",
			MSG_SWIFT : "SWIFT/BIC",
			MSG_SWIFT_DESC : "Un code SWIFT est un format standard pour les codes d'identification d'entreprise (BIC). Les banques et les institutions financières les utilisent pour s'identifier globalement.",
			MSG_POSTAL_ORDER : "Mondat Postale",
			MSG_CASH : "Espèces à la réception",
			MSG_TICKET_RESTAURANT : "Ticket Restaurant",
			MSG_BANK_CARDS : "Cartes bancaires locales",
			MSG_CLICTOPAY_DESC : '<a href="http://www.clictopay.com.tn" target="_blank">Plateforme de paiement de dernière génération ClicToPay traite tous les flux des marchands ainsi que toutes les transactions. ClicToPay opère et exploite un service de paiement en relation avec les différents acteurs du commerce électronique :</a>',
			MSG_CLICTOPAY_LOGIN : 'Login',
			MSG_CLICTOPAY_PASSWORD : 'Mot de passe',
			MSG_D17 : 'D17 DIGIPOSTBANK',
			MSG_ERROR_RIP : "Le RIB (Relevé d'Identité Bancaire) est obligatoire",
			MSG_ERROR_IBAN : "L'IBAN est obligatoire",
			MSG_ERROR_SWIFT : "Code SWIFT est obligatoire",
			MSG_ERROR_CLICTOPAY_LOGIN : "ClicToPay login est obligatoire",
			MSG_ERROR_CLICTOPAY_PASSWORD : "ClicToPay mot de passe est obligatoire",
			MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
			MSG_TO_VALIDATE : "Valider",
		},
		SECURITY : {
			MSG_TITLE_SECURITY : "Sécurité et connexion",
			MSG_DESC_SECURITY : "Changer le mot de passe",
			MSG_NEW_SECURITY : "Nouveau",
			MSG_CONFIRM_SECURITY : "Confirmer",
			MSG_LOWERCASE_REQUIRED : "Une lettre minuscule requise.",
			MSG_UPPERCASE_REQUIRED : "Une lettre majuscule requise.",
			MSG_8_REQUIRED : "8 caractères minimum.",
			MSG_NUMBER_REQUIRED : "Un numéro requis.",
			MSG_PASSWORD_REPASSWORD : "Les mots de passe ne correspondent pas.",
			MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
			MSG_TO_VALIDATE : "Valider",
		}
	},
	TEMPLATE: {
		MSG_SAVE : "Enregistrer",
		MSG_PAGE_DESC : "Couleurs des Boutons d'action principale",
		MSG_COLOR_BTN : "Couleur des Boutons",
		MSG_COLOR_TXT_BTN : "Couleur de text de  Bouton",
		MSG_COLOR_CATEGORYS : "Couleurs des Catégories",
		MSG_COLOR_CATEGORY : "Couleur des Catégories",
		MSG_COLOR_TXT_CATEGORY : "Couleur de text des Catégories",
		MSG_COLOR_TEMPLATE_TITLE : "Template",
		MSG_COLOR_TXT_COMING_SOON : "Coming soon",
		MSG_ERROR_HAS_OCCURRED : "Une erreur s’est produite. Nous nous efforçons de la corriger dans les plus brefs délais.",
		MSG_SUCCESS_OPERATION : "Mise a jour effectie avec succes",
	},
};













