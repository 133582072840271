// USA
export const locale = {
	APP_NAME: "Doken",
	APP_URL: "https://try.doken.co",
	MSG_LOGOUT: "Log out",
	MSG_UNIQUE_IDENTITY: "Unique Identity",
	MSG_BANK_TRANSFER: "Bank transfer",
	MSG_CASH: "Cash on delivery",
	MSG_DIGIPOSTBANK: "D17",
	MSG_POSTAL_ORDER: "Postal money order",
	MSG_TICKET_RESTAURANT: "Ticket Restaurant",
	MSG_CLICTOPAY: "Bank cards",
	MSG_DELIVERY: "Delivery",
	MSG_SELF_PICKUPS: "Collection",
	MSG_IN_RESTO: "At the restaurant",
	MENU: {
		MSG_ORDER: "Order",
		MSG_CATEGORY: "Category",
		MSG_PRODUCT: "Product",
		MSG_SETTINGS: "Configuration",
		MSG_DESIGN: "Design & creation",
	},
	AUTH: {
		MSG_EMAIL_ADDRESS_PHONE_NUMBER: "Email address or Phone number",
		MSG_PASSWORD: "Password",
		MSG_FORGET_PASSWORD: "Forgot your password?",
		MSG_LOGIN: "Login",
		MSG_NO_ACCOUNT: "No account?",
		MSG_CREATE_ACCOUNT: "Create an account",
		MSG_ENTER_EMAIL_PHONE_RESET_PASSWORD: "Enter your email or Phone number to reset your password",
		MSG_SEND: "Send",
		MSG_CANCEL: "Cancel",
		MSG_CONTACT: "Contact",
		MSG_IN_REGARDS_TO: "Contact",
		MSG_SUPPORT: "Support",
		MSG_ERROR_LOGIN_PASSWORD: "Incorrect email address or password",
		MSG_ERROR_HAS_OCCURRED: "An error has occurred. We are working to correct it as soon as possible.",
	},
	SIGNUP: {
		MSG_SHOP_NAME: "Name of the online store",
		MSG_REGISTER: "Register",
		MSG_CREATE_STORE_NOW: "Create your store now",
		MSG_CATEGORY: "Category",
		MSG_COUNTRY: "Country",
		MSG_PHONE_NUMBER: "Phone number",
		MSG_PASSWORD: "Password",
		MSG_CONFIRM_PASSWORD: "Confirm password",
		MSG_EMAIL: "Email address",
		MSG_TERMS_OF_USE: 'By clicking on <b> Accept and register </b>, you agree to the <a href="https://try.doken.co" class="ml-2"> <b> Terms of usage </b> </a> and the <b> Cookie Policy </b>',
		MSG_ACCEPT_AND_REGISTER: "Accept and register",
		MSG_LOGIN: "Login",
		MSG_ERROR_SHOP_NAME_EXISTS: "Shop name already exists",
		MSG_ERROR_MAIL_NAME_EXISTS: "the email address already exists",
		MSG_ERROR_PHONE_NAME_EXISTS: "Phone number already exists",
		MSG_ERROR_MAIL_FORMAT: "Please enter a valid email address",
		MSG_ERROR_ALL_FIELDS: "All fields are mandatory",
		MSG_ERROR_CATEGORY_REQUIRED: "Mandatory category",
		MSG_ERROR_COUNTRY_REQUIRED: "Country required",
		MSG_ERROR_PHONE_REQUIRED: "Mandatory phone number",
		MSG_ERROR_PASSWORD_REQUIRED: "Mandatory phone number",
		MSG_ERROR_PASSWORD_REPASSWORD: "Your password and confirmation password do not match.",
		MSG_SEND_SMS_TEXT_TITLE: "You will receive a confirmation SMS or e-mail, click on the link to activate your account.",
		MSG_SEND_SMS_TEXT_DESC: "A message with a 6-digit verification code has just been sent.",
		MSG_ERROR_PRODUCT: "An error has occurred. We are working to correct it as soon as possible.",
	},
	VERIFSMS: {
		MSG_TITLE_COMPTE_OK: "Your account has been created successfully",
		MSG_DESC_COMPTE_OK: "You must now use your account",
		MSG_TITLE_COMPTE_NOT_OK: "Validate your account",
		MSG_DESC_COMPTE_NOT_OK: "Cannot use this code for validation",
		MSG_VALIDE: "Validate your account",
		MSG_ENTER_CODE: "Enter the code",
	},
	ORDER: {
		MSG_DATE_START: "Start date",
		MSG_DATE_END: "End date",
		MSG_UPDATE: "Update",
		MSG_CANCEL: "Cancel",
		MSG_OK: "Ok",
		MSG_DELETE: "Delete",
		MSG_EMPTY_DATA: "There are no commands to display",
		MSG_FIND_DATA: "Type to search",
		MSG_MEANS_PAYMENT: "Payment method:",
		MSG_ORDER: "Delivery:",
		MSG_TABLE: "Table:",
		MSG_USER: "Customer:",
		MSG_SHIPPING_COST: "Shipping costs:",
		MSG_Tax: "Tax:",
		MSG_TOTAL: "Total to pay:",
		MSG_STATUS: "Status:",
		MSG_PAYMENT: "Paymment:",
		MSG_NOTE: "Note:",
		MSG_SEND_NOTIF_TO_USER: "Send a notification to the client",
		MSG_SAVE: "Save the modifications",
		MSG_ORDER_CURRENT_ORDERS: "Orders in progress",
		MSG_ORDER_WAITING: "Pending",
		MSG_ORDER_CONFIRMED: "Confirmed",
		MSG_ORDER_BEING_PROCESSED: "Processing",
		MSG_ORDER_SHIPPED: "Shipped",
		MSG_ORDER_FINALIZED: "Finalized",
		MSG_ORDER_RECEIVED: "Received",
		MSG_ORDER_CANCELED: "Canceled",
		MSG_ORDER_CURRENT_ORDERS_DESCRIPTION: "",
		MSG_ORDER_WAITING_DESCRIPTION: "% shop% thanks you for your trust! The order% num% is awaiting validation by our teams. \n N% Note%",
		MSG_ORDER_CONFIRMED_DESCRIPTION: "The order% num% is Confirmed. \n N% Note%",
		MSG_ORDER_BEING_PROCESSED_DESCRIPTION: "The command% num% is being processed. \n N% Note%",
		MSG_ORDER_SHIPPED_DESCRIPTION: "The order% num% is being delivered. \n N% Note%",
		MSG_ORDER_FINALIZED_DESCRIPTION: "The order% num% is Finalized. \n n% Note% ",
		MSG_ORDER_RECEIVED_DESCRIPTION: "The order% num% is delivered. \n N% Note%",
		MSG_ORDER_CANCELED_DESCRIPTION: "The command% num% is Canceled. \n N% Note%",
		MSG_ORDER_PAYMENT_PENDING: "Payment Pending",
		MSG_ORDER_PAID: "Paid",
		MSG_ORDER_FAILED_PAYMENT: "Payment Failed",
		MSG_ORDER_NUMBER: "Order number:",
	},
	PRODUCT: {
		MSG_ADD: "Add",
		MSG_REORGANIZE: "Reorganize",
		MSG_SAVE: "Save",
		MSG_MASK: "Mask",
		MSG_SHOW: "Show",
		MSG_UPDATE: "Update",
		MSG_EMPTY_CATEGORY: "There are no articles in this category",
		MSG_FIRST_PRODUCT: "Create the 1st product",
		MSG_FIRST_PRODUCT_URL: "https://www.youtube.com/embed/yO7kQQ3YWzY",
		MSG_FIRST_PRODUCT_ADD: "Add 1st product",
		MSG_PRODUCT: "Product",
		MSG_PRODUCT_NAME: "Name:",
		MSG_PRODUCT_DESC: "Description:",
		MSG_PRODUCT_PRICE: "Price:",
		MSG_PRODUCT_PIC: "product image",
		MSG_PRODUCT_FILE_TYPE: "Allowed file types: png, jpg, jpeg",
		MSG_CATEGORY: "Category",
		MSG_ERROR_READ_FILE: "Unable to read file. Format is not supported",
		MSG_ERROR_EMPTY_NAME: "Name is required",
		MSG_ERROR_EMPTY_DESC: "Description is mandatory",
		MSG_ERROR_EMPTY_PRICE: "Price is required",

		MSG_ALERT_STATUS_TITLE: "Are you sure?",
		MSG_ALERT_STATUS_TEXT: "Do you want to change the status of this article!",
		MSG_ALERT_REMOUVE_OPERA: "Operation canceled",

		MSG_SEND: "Validate",
	},
	CATEGORY: {
		MSG_ADD: "Add",
		MSG_REORGANIZE: "Reorganize",
		MSG_SAVE: "Save",
		MSG_EMPTY_CATEGORY: "There are no articles in this category",
		MSG_FIRST_CATEGORY: "Create the 1st category",
		MSG_FIRST_CATEGORY_URL: "https://www.youtube.com/embed/yO7kQQ3YWzY",
		MSG_FIRST_CATEGORY_ADD: "Add 1st category",
		MSG_CATEGORY: "Category",
		MSG_SEND: "Send",
		MSG_NAME: "Name:",
		MSG_ALERT_STATUS_TITLE: "Are you sure?",
		MSG_ALERT_STATUS_TEXT: "Do you want to change the status of this category!",
		MSG_ERROR_EMPTY_NAME: "Category Name is required",
		MSG_ALERT_REMOUVE_OPERA: "Operation canceled",
	},
	SETTINGS: {
		MSG_GENERAL_INFORMATIONS: "General information",
		MSG_COMMAND_PARAMETERS: "Command parameters",
		MSG_SALES_CHANNEL: "Sales channel",
		MSG_PAYMENT: "Payment",
		MSG_SECURITY_CONNECTION: "Security and connection",
		MSG_CUSTOM_DOMAIN: "Custom domain",
		MSG_EMAIL: "Email address:",
		MSG_CUSTOMER_NAME: "Company name:",
		MSG_PHONE_NUMBER: "Phone number:",
		GENERAL_INFORMATIONS: {
			MSG_TITLE_GENERAL_INFORMATIONS: "General information",
			MSG_TO_VALIDATE: "Validate",
			MSG_STORE_DETAILS: "Store details",
			MSG_LOGO: "Logo",
			MSG_FILE_TYPE: "Allowed file types: png, jpg, jpeg.",
			MSG_CATEGORY_BUSINESS: "Business Category",
			MSG_SHOP_NAME: "Shop name",
			MSG_DESCRIPTION: "Description",
			MSG_COMPANY_NAME: "Company name",
			MSG_TAX_IDENTIFICATION: "Tax registration number",
			MSG_COUNTRY: "Country",
			MSG_ADRESSE: "Address",
			MSG_SHOP_NUMBER: "Shop number",
			MSG_STORE_EMAIL_ADDRESS: "Store e-mail address",
			MSG_CONTACT_INFO: "Contact Info",
			MSG_CONTACT_FIRSTNAME: "First name",
			MSG_CONTACT_LASTNAME: "Name",
			MSG_CONTACT_NUMBER: "Contact number",
			MSG_EMAIL: "Email address",
			MSG_COMPANY_WEBSITE: "Company site",
			MSG_READ_FILE: "Unable to read file. Format is not supported",
			MSG_SUCCESS_OPERATION: "Update successfully completed",
		},
		DOMAIN: {
			MSG_TITLE_DOMAIN: "Use your own domain",
			MSG_DESC_DOMAIN: "You can benefit from all the advantages even if you already have a domain name from another provider.",
			MSG_TO_VALIDATE: "Validate",
			MSG_SHOP_ADDRESS_LOCAL: "Shop local address",
			MSG_SHOP_TOOLTIP_1: "You can only have one subdomain name for your store, and you cannot choose a subdomain name already in use by someone else.",
			MSG_SHOP_TOOLTIP_2: "Names can only contain alphanumeric characters (A to Z, 0 to 9) and hyphens (-). They must not contain generic terms or extensions (.com or .net).",
			MSG_SHOP_TOOLTIP_3: "Usernames must be at least 5 characters long.",
			MSG_SHOP_LINKED_STORE: "Domain to link to your store",
			MSG_SHOP_CREATE_A_RECORD: "Create an A record on your domain registrar and point it to",
			MSG_SHOP_TOOLTIP_4: "You can only have one subdomain name for your store, and you cannot choose a subdomain name already in use by someone else.",
			MSG_SHOP_TOOLTIP_5: "Names can only contain alphanumeric characters (A to Z, 0 to 9) and hyphens (-). They must not contain generic terms or extensions (.com or .net).",
			MSG_SHOP_TOOLTIP_6: "Usernames doiwind have at least 5 characters. ",
			MSG_INVALID_DOMAIN_FORMAT: "Invalid domain format",
			MSG_SUCCESS_OPERATION: "Update successfully completed",
			MSG_STORE_NAME_ALREADY_USE: "The store name already in use",
			MSG_STORE_NAME_LONG: "The address store name must be at least 5 characters long.",
			MSG_STORE_URL_PUBLIC: "Public address",
			MSG_STORE_URL_PRIVET: "Your domain name (boutique.com OR shop.boutique.com)",
		},
		ORDER: {
			MSG_TITLE_ORDER: "Command parameters",
			MSG_TO_VALIDATE: "Validate",
			MSG_CHOOSE_CURRENCY: "Choose a currency",
			MSG_CHOOSE_FORM_CURRENCY: "Choose a currency format",
			MSG_CHOOSE_RIGHT_LEFT: "Left / Right",
			MSG_ACCEPT_ORDERS_FOR: "Accept orders for",
			MSG_HOME_DELIVERY: "Home delivery",
			MSG_SELF: "Collection",
			MSG_AT_TABLE: "At the table",
			MSG_DELIVERING_TO: "Delivering to:",
			MSG_DELIVERING_TO_PLACEHOLDER: "Delivery area: Grand Tunis, .....",
			MSG_COMMAND_SETTINGS: "Command parameters:",
			MSG_MINIMUM_ORDER_DELIVERY: "Minimum order for delivery",
			MSG_MINIMUM_ORDER_FREE_DELIVERY: "Minimum order for free delivery",
			MSG_SHIPPING_COST: "Shipping costs",
			MSG_SHIPPING_TAX: "Tax",
			MSG_SUCCESS_OPERATION: "Update successfully completed",
		},
		SALES_CHANNEL: {
			MSG_TITLE_SALES_CHANNEL: "Sales channel",
			MSG_DES_WHATSAPP: "Receive orders on your WhatsApp number",
			MSG_START: "Start",
			MSG_DES_FACEBOOK: "Connect your store to your Facebook page <br> (administrator permissions required)",
			MSG_DES_SMS: "Turns out semicolon-less style is easier and safer in TS because most gotcha edge cases are type invalid as well.",
			MSG_DES_SMTP: "Send emails directly from your chatbot via your own SMTP server",
			MSG_TITLE_POPUP_FB: "Messenger settings",
			MSG_REFRESH: "Refresh",
			MSG_CREATE_PAGE: "Create a page",
			MSG_TITLE_POPUP_WA: "WhatsApp settings",
			MSG_TITLE_NUMBER_WA: "WhatsApp number",
			MSG_TITLE_POPUP_SMS: "The SMS service is a service with charging additional costs",
			MSG_FEATURE_UNDER_DEVELOPMENT: "Feature under development",
			MSG_ERROR_CNX_FB: "An error occurred while connecting to Facebook! <br> Please try again.",
			MSG_ERROR_WA: "A WhatsApp number is required to use Notification on WhatsApp",
			MSG_ERROR_SMS: "A telephone number is required",
			MSG_SUCCESS_OPERATION: "Update successfully completed",
			MSG_TO_VALIDATE: "Validate",
		},
		PAYMENT: {
			MSG_TITLE_PAYMENT: "Payment Parameters",
			MSG_DESC_PAYMENT: "Add a payment method",
			MSG_DESC_BANK_TRANSFER: "You can receive a transfer to your account located at any bank. All you need to do is provide you with:",
			MSG_RIP: "RIP",
			MSG_RIP_DESC: "The RIB (Bank Identity Statement) if his account is in a local bank.",
			MSG_IBAN: "IBAN",
			MSG_IBAN_DESC: "The IBAN is the international format for your checking account number. The IBAN format helps automate international payments and transfers and reduce costs.",
			MSG_SWIFT: "SWIFT / BIC",
			MSG_SWIFT_DESC: "A SWIFT code is a standard format for business identification codes (BICs). Banks and financial institutions use them to identify themselves globally.",
			MSG_POSTAL_ORDER: "Mondat Postale",
			MSG_CASH: "Cash at reception",
			MSG_TICKET_RESTAURANT: "Ticket Restaurant",
			MSG_BANK_CARDS: "Local bank cards",
			MSG_CLICTOPAY_DESC: '<a href="http://www.clictopay.com.tn" target="_blank"> Latest generation payment platform ClicToPay processes all merchant flows as well as all transactions. ClicToPay operates and operates a payment service in relation to the various players in electronic commerce: </a> ',
			MSG_CLICTOPAY_LOGIN: 'Login',
			MSG_CLICTOPAY_PASSWORD: 'Password',
			MSG_D17: 'D17 DIGIPOSTBANK',
			MSG_ERROR_RIP: "The RIB (Bank Identity Statement) is mandatory",
			MSG_ERROR_IBAN: "IBAN is mandatory",
			MSG_ERROR_SWIFT: "SWIFT code is required",
			MSG_ERROR_CLICTOPAY_LOGIN: "ClicToPay login is required",
			MSG_ERROR_CLICTOPAY_PASSWORD: "ClicToPay password is required",
			MSG_SUCCESS_OPERATION: "Update successfully completed",
			MSG_TO_VALIDATE: "Validate",
		},
		SECURITY: {
			MSG_TITLE_SECURITY: "Security and connection",
			MSG_DESC_SECURITY: "Change password",
			MSG_NEW_SECURITY: "New",
			MSG_CONFIRM_SECURITY: "Confirm",
			MSG_LOWERCASE_REQUIRED: "A lowercase letter required.",
			MSG_UPPERCASE_REQUIRED: "An uppercase letter required.",
			MSG_8_REQUIRED: "8 characters minimum.",
			MSG_NUMBER_REQUIRED: "A number required.",
			MSG_PASSWORD_REPASSWORD: "The passwords do not match.",
			MSG_SUCCESS_OPERATION: "Update successfully completed",
			MSG_TO_VALIDATE: "Validate",
		}
	},
	TEMPLATE: {
		MSG_SAVE: "Save",
		MSG_PAGE_DESC: "Main action button colors",
		MSG_COLOR_BTN: "Button Color",
		MSG_COLOR_TXT_BTN: "Button text color",
		MSG_COLOR_CATEGORYS: "Colors of Categories",
		MSG_COLOR_CATEGORY: "Color of Categories",
		MSG_COLOR_TXT_CATEGORY: "Category text color",
		MSG_COLOR_TEMPLATE_TITLE: "Template",
		MSG_COLOR_TXT_COMING_SOON: "Coming soon",
		MSG_ERROR_HAS_OCCURRED: "An error has occurred. We are working to correct it as soon as possible.",
		MSG_SUCCESS_OPERATION: "Update successfully completed",
	},
};













